import React from 'react'
import { CardMedia, withStyles } from '@material-ui/core'
import Background from './images/comingsoonBG.jpg'

const styles = {
    background: {
        height: '78vh',
        width: '85vw',
        maxWidth: '1450px',
        minWidth: '1450px',
        maxHeight: '800px',
        minHeight: '800px',
    },
}
const About = ({ classes }) => {
    return (
        <div className={classes.root}>
            {/*<Title title={'Grey Wizard Tech'}/>*/}
            <CardMedia
                className={classes.background}
                image={Background}
                title="Background"
                component="img"
            />
        </div>
    )
}

export default withStyles(styles)(About)
