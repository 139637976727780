import React from 'react'
import Iframe from 'react-iframe'
import Spinner from 'react-spinkit'

export default class Integrate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
        }
    }

    hideSpinner = () => {
        this.setState({
            loading: false,
        })
    }

    render() {
        return (
            <div style={{ height: '90vh', minHeight: '70vh' }}>
                {this.state.loading ? (
                    <div
                        style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            marginBottom: '85px',
                        }}
                    >
                        <Spinner
                            className="loading text-center"
                            name="ball-spin-fade-loader"
                            color="white"
                            fadeIn="none"
                            style={{ left: '40%' }}
                        />
                    </div>
                ) : null}
                <Iframe
                    url={
                        process.env.REACT_APP_MARKETPLACE_LINK +
                        this.props.location.search
                    }
                    width="100%"
                    height="100%"
                    id="myId"
                    className="myClassname"
                    ariaHidden={this.state.loading}
                    display="initial"
                    position="relative"
                    onLoad={this.hideSpinner}
                    frameBorder={0}
                />
            </div>
        )
    }
}
