import React from 'react'
import { Layout } from 'react-admin'
import GWMenu from './GWMenu'
import Background from './images/GWBackground.jpg'
import Logo from './images/Greenwood Tech.png'
import { AppBar, Typography, withStyles } from '@material-ui/core/'

const appBarStyles = {
    title: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        color: 'white',
    },
    spacer: {
        flex: 1,
    },
    root: {
        backgroundColor: 'transparent',
        position: 'relative',
        boxShadow: 'none',
        height: '80px',
    },
    logo: {
        width: '100px',
        height: '100px',
    },
    link: {
        textDecoration: 'none',
        display: 'flex',
        flex: '1',
        width: '315px',
        height: '100px',
    },
}

const MyAppBar = ({ props, classes }) => (
    <AppBar className={classes.root} {...props}>
        <a
            href="https://pandium.com"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
        >
            <img
                id={'logoImage'}
                src={Logo}
                className={classes.logo}
                alt="Logo"
            />
            <Typography
                variant="title"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            >
                {' '}
                Grey Wizard Tech{' '}
            </Typography>
        </a>
        <span className={classes.spacer} />
    </AppBar>
)

const StyledAppBar = withStyles(appBarStyles)(MyAppBar)

const GWLayout = (props) => (
    <Layout
        {...props}
        menu={GWMenu}
        style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
        }}
        appBar={StyledAppBar}
    />
)

export default GWLayout
