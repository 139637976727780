import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Keycloak from 'keycloak-js'
import * as configs from './config'
import { manipulateDOM } from './Settings'

const keycloak = Keycloak(configs.KeycloakConfig)

keycloak
    .init({ onLoad: 'login-required' })
    .success(() => {
        sessionStorage.setItem('kc', JSON.stringify(keycloak))
        sessionStorage.setItem('token', keycloak.token)
        ReactDOM.render(<App />, document.getElementById('root'))
        const updates = JSON.parse(sessionStorage.getItem('layoutUpdate'))
        if (updates) {
            manipulateDOM(updates, 'false')
        }
    })
    .error(() => {
        //TODO: 'Will need a 500 "page"'. May we can push to some state here...
        console.error('Bruh')
    })

keycloak.onTokenExpired = () => {
    keycloak
        .updateToken()
        .success(() => {
            sessionStorage.setItem('token', keycloak.token)
        })
        .error(() => {
            console.debug('Failed to refresh token')
        })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
