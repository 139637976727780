import React from 'react'
import { Route } from 'react-router'
import Integrate from './Integrate'
import About from './About'
import { Settings } from './Settings'

export default [
    <Route
        exact
        path="/Integrate"
        render={(props) => <Integrate {...props} />}
    />,
    <Route exact path="/About" component={About} />,
    <Route exact path="/Settings" component={Settings} />,
]
