import React from 'react'
import { connect } from 'react-redux'
import { MenuItemLink, getResources } from 'react-admin'
import { withRouter } from 'react-router'
import LabelIcon from '@material-ui/icons/Label'

const GWMenu = ({ resources, onMenuClick }) => (
    <div>
        <MenuItemLink
            to="/"
            primaryText="Home"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            style={{ color: 'white' }}
        />
        <MenuItemLink
            to="/About"
            primaryText="Dashboard"
            leftIcon={<LabelIcon />}
            onClick={onMenuClick}
            style={{ color: 'white' }}
        />
        <MenuItemLink
            to="/Integrate"
            primaryText="Apps & Integrations"
            leftIcon={<LabelIcon />}
            onClick={() => {}}
            style={{ color: 'white' }}
        />
        <MenuItemLink
            to="/Integrate?my_apps=true"
            primaryText="My Apps"
            leftIcon={<LabelIcon />}
            onClick={() => {}}
            style={{ color: 'white' }}
        />
        <MenuItemLink
            disabled={true}
            to="/"
            primaryText="Reporting"
            leftIcon={<LabelIcon />}
            onClick={() => {}}
            style={{ color: 'white' }}
        />
        <MenuItemLink
            to="/Settings"
            primaryText="Settings"
            leftIcon={<LabelIcon />}
            onClick={() => {}}
            style={{ color: 'white' }}
        />
    </div>
)

const mapStateToProps = (state) => ({
    resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(GWMenu))
