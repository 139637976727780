import React from 'react'
import Card from '@material-ui/core/Card'
import { CardMedia, withStyles } from '@material-ui/core'
import Background from './images/dashboardBG.png'

const styles = {
    background: {
        height: '78vh',
        width: '85vw',
        maxWidth: '1460px',
        minWidth: '1460px',
        maxHeight: '1020px',
        minHeight: '1020px',
    },
    root: {
        height: '100%',
        width: '100%',
        backgroundColor: '#eeeeee',
    },
}

var newBg = null
const Dashboard = ({ classes }) => {
    const updates = JSON.parse(sessionStorage.getItem('layoutUpdate'))
    if (updates && updates.hasOwnProperty('bgImage'))
        newBg = updates.bgImage.rawFile.preview

    return (
        <div className={classes.root}>
            <Card className={classes.root}>
                <CardMedia
                    className={classes.background}
                    image={newBg ? newBg : Background}
                    title="Background"
                    component="img"
                />
            </Card>
        </div>
    )
}

export default withStyles(styles)(Dashboard)
