import React from 'react'
import {
    ImageInput,
    ImageField,
    SimpleForm,
    FormTab,
    TextInput,
} from 'react-admin'
import './index.css'

export const manipulateDOM = (record, update = true) => {
    if (record.headerTitle) {
        document.getElementById('react-admin-title').innerHTML =
            record.headerTitle
    }
    if (record.bgColor) {
        document.querySelector('.layout').style.backgroundImage = null
        document.querySelector('.layout').style.backgroundColor = record.bgColor
    }
    if (record.bgUrl) {
        document.querySelector('.layout').style.backgroundImage =
            'url(' + record.bgUrl + ')'
    }

    if (record.logoUrl) {
        const element = document.getElementById('logoImage')
        element.src = record.logoUrl
        element.style.padding = '10px'
        element.style.height = '60px'
        element.style.width = 'auto'
    }
    if (record.titleColor) {
        document.getElementById('react-admin-title').style.color =
            record.titleColor
    }
    if (record.sbTextColor) {
        const color = record.sbTextColor
        document
            .querySelectorAll('[role="menuitem"]')
            .forEach((el) => (el.style.color = color))
    }
    if (update) sessionStorage.setItem('layoutUpdate', JSON.stringify(record))
}

const EditForm = (props) => {
    return (
        <div
            style={{
                backgroundColor: 'white',
                textAlign: 'left',
                color: '#4287f5',
            }}
        >
            <SimpleForm margin={'normal'} {...props}>
                <h2> Settings </h2>
                <TextInput source="headerTitle" label="Header Title" />
                <box display={'inline-flex'}>
                    <div>
                        <TextInput source="logoUrl" label="Logo URL" />
                        <text> Remember to change connector logo</text>
                    </div>
                    <div>
                        <TextInput
                            source="bgUrl"
                            label="Background Image Url"
                        />
                        <text>
                            {' '}
                            Background Image will override background color
                        </text>
                    </div>
                </box>
                <h4>Colors: </h4>
                <box display={'inline-flex'}>
                    <TextInput
                        style={{ margin: '20px' }}
                        source="bgColor"
                        label="Background Color "
                    />
                    <TextInput
                        style={{ margin: '20px' }}
                        source="titleColor"
                        label="Title Text Color "
                    />
                    <TextInput
                        style={{ margin: '20px' }}
                        source="sbTextColor"
                        label="Side Bar Text Color "
                    />
                    <a
                        href="https://www.google.com/search?q=color+picker"
                        target={'_blank'}
                    >
                        Use color picker
                    </a>
                </box>
                <br />
                <h4>Dashboard Image for Home page</h4>
                <ImageInput
                    source="bgImage"
                    label={'upload screenshot'}
                    accept="image/*"
                    placeholder={<p>Drop your file here</p>}
                >
                    <ImageField source="src" title="" />
                </ImageInput>
            </SimpleForm>
        </div>
    )
}

export const Settings = ({ classes }) => <EditForm save={manipulateDOM} />
